<template>
  <div class="project australia australia2">
    <div class="top">
      <img :src="`${QNHost}/Project/Australia2/01.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <img class="mobile-show" :src="`${QNHost}/Project/Australia2/mobile/01.png`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <div class="con">
        <h5>188C澳洲显赫投资者签证</h5>
        <p class="time"></p>
        <p class="tip">高净值人群的快速移民通道</p>
      </div>
      <div class="tags">
        <div class="tag">安心</div>
        <div class="tag">省心</div>
        <div class="tag">舒心</div>
      </div>
    </div>

    <div class="sign">
      <div class="con">
        <div class="detail">
          <h3>188C澳洲显赫投资者签证</h3>
          <img :src="`${QNHost}/Project/Australia2/09.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
          <p>高净值人群的澳洲移民快捷通道</p>
          <div>
            <p>澳洲188C显赫投资者签证是澳洲政府为了吸引能为澳洲经济做出巨大投资贡献的申请人特别设立的临居类签证。</p>
          </div>
        </div>
        <div class="imgs">
          <img class="pc-show" :src="`${QNHost}/Project/Australia2/02.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
          <img class="mobile-show" :src="`${QNHost}/Project/Australia2/mobile/02.png`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
          <img class="pc-show" :src="`${QNHost}/Project/Australia2/03.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
          <img class="mobile-show" :src="`${QNHost}/Project/Australia2/mobile/03.png`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
        </div>
      </div>
    </div>

    <div class="mid-banner">
      <!-- <img :src="`${QNHost}/Project/Australia2/04.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民"> -->
      <div class="background-media"></div>
      <div class="con">
        <h5>适宜人群</h5>
        <p>企业主、高收入、高资产并有意向投资澳洲的人群</p>
        <i>HOPEWINNER</i>
      </div>
    </div>

    <div class="advantage">
      <img :src="`${QNHost}/Project/Australia2/05.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      <div class="con">
        <h5 style="color:#ffffff">项目优势</h5>
        <img :src="`${QNHost}/Project/Australia2/08.png`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
        <p>1、无年龄、学历及语言限制</p>
        <p>2、无经商背景要求，无需在澳洲创办生意</p>
        <p>3、无需参加移民局面试</p>
        <p>4、居住时间要求极其宽松</p>
      </div>
    </div>

    <div class="need">
      <div class="con">
        <h5>申请要求</h5>
        <div class="lists">
          <p>1、申请人拥有不少于500万澳币的个人资产；</p>
          <p>2、需要获得州或领地政府或澳大利亚贸易委员会提名；</p>
          <p>3、投资至少500万澳币到符合规定的投资领域中；</p>
          <p>·至少50万澳币至澳洲风险投资或私募产品；</p>
          <p>·至少150万至合规小型企业基金或澳洲证券交易所（ASX）<br/>上市的小型企业；</p>
          <p>·最多300万“余额投资“至管理基金或投资到其他合规资产，<br/>包括澳交所上市公司、合规公司债券或期票、年金和不动产的上<br/>市投资公司。</p>
        </div>
      </div>
      <div class="imgs">
        <img class="pc-show" :src="`${QNHost}/Project/Australia2/06.jpg`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
        <img class="mobile-show" :src="`${QNHost}/Project/Australia2/mobile/06.png`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
        <img class="mobile-show" :src="`${QNHost}/Project/Australia2/mobile/07.png`" alt="成都移民公司,加拿大移民,加拿大创业投资移民">
      </div>
    </div>

    <div class="question">
      <div>
        <h4>常见问题 <span>Q&A</span></h4>
        <p>Q：188C如何转成永居身份？</p>
        <p>A：转888C签证需要州政府提名，申请者必须在188C有效期内递交申请。因此，申请者可以提早申请州担保，并在投资后的三年11个月后递签。州政府一般会在48小时内给出提名或不提名的决定。</p>
        <br/>
        <p>Q：转永居身份需要在澳洲境内吗？</p>
        <p>A：888C的递交要求比较宽松，不论申请人是否在澳洲境内，只要符合递交要求就可以递交申请。</p>
      </div>
    </div>

  </div>
</template>
<script>
import mixin from '@/assets/projectAnimateMixin.js';

export default {
  name: 'canada',
  mixins: [mixin]
};
</script>

<style lang="scss" scoped>
  @import '../../assets/project.scss';
  .background-media {
    background-image: url($QNHost + "/Project/Australia2/3.jpg");
  }
  .time {
        // font-family: 'SourceHanSansCN-ExtraLight';
        position: relative;
        &:after {
          position: absolute;
          content: ' ';
          width: 3%;
          left: 49%;
          bottom: 0px;
          border-bottom: 8px solid #ffffff;
        }
    }
</style>
